import PendingList from "./sections/PendingList"
import User from "./sections/User"
import AllUsers from "./sections/AllUsers";
import Quest from "./sections/Quest";

const exports = {
    "pending_users": PendingList,
    "user": User,
    "all_users": AllUsers,
    "quest": Quest
}

export default exports