const getAxiosConfig = (session, isMultipart = false) => {
    const ret = {headers: {}}

    if (session && session.access_token) {
        ret.headers.Authorization = `Bearer ${session.access_token}`
    }

    if(isMultipart)
        ret.headers["Content-Type"] = "multipart/form-data"
    return ret
}

const parseDate = (date) => {
    if (typeof date === 'number') {
        return new Date(date);
    } else {
        return new Date(date);
    }
}

const calcDuration = (startTime, endTime) => {
    const parsedStartTime = parseDate(startTime);
    const parsedEndTime = parseDate(endTime);

    const timeDifference = parsedEndTime.getTime() - parsedStartTime.getTime();

    const seconds = timeDifference / 1000;

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsRemaining = seconds % 60;

    let timeDifferenceString = '';
    if (hours > 0) {
        timeDifferenceString += `${hours}h `;
    }
    if (minutes > 0) {
        timeDifferenceString += `${minutes}min `;
    }
    if (secondsRemaining > 0) {
        timeDifferenceString += `${secondsRemaining}s `;
    }

    return timeDifferenceString.trim();
}

const webUrl = "https://bingo.infotech.edu.pl"
const apiUrl = "https://bingo-r17v.onrender.com"
const adminList = ["katarzyna.polak@infotech.edu.pl", "oskar.raszkiewicz@infotech.edu.pl", "daniel.kresso@infotech.edu.pl"]
const requiredDomain = "@infotech.edu.pl"
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNwb2tldmJsYm1ldXlvcWlqanhpIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc1NzczNDUsImV4cCI6MjAxMzE1MzM0NX0.88nJx2h8mGAiXc1aH988nD-koQKaeMAOsrGS7QEKo-M"

module.exports = {getAxiosConfig, webUrl, apiUrl, adminList, requiredDomain, supabaseKey, calcDuration, parseDate};