import React from "react";

const AdminDashboard = () => {
    return (<div className={"admin-dashboard"}>
        <a href={"/admin/pending_users"}>
            <div className="admin-item">
                <h3>Zadania do sprawdzenia</h3>
                <p>
                    Lista uczniów z niesprawdzonymi zadaniami
                </p>
            </div>
        </a>
        <a href={"/admin/all_users"}>
            <div className="admin-item">
                <h3>Wszyscy uczniowie</h3>
                <p>
                    Lista wszystkich uczniów
                </p>
            </div>
        </a>
    </div>)
}

export default AdminDashboard;