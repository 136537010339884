import React, {useEffect, useState} from "react"
import {useSession, useSupabaseClient} from "@supabase/auth-helpers-react";
import {requiredDomain, webUrl} from "../common";

const LoginRequired = ({children}) => {
    const supabase = useSupabaseClient();
    const session = useSession()
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [isInitialized, setIsInitialized] = useState(false)

    useEffect(() => {
        setIsLoggedIn(session && session.user.email.endsWith(requiredDomain))
        setIsInitialized(true)
    }, [session])

    async function googleSignIn() {
        const {error} = await supabase.auth.signInWithOAuth({
            provider: 'google', options: {
                redirectTo: `https://codeweek-lilac.vercel.app/`, scopes: 'https://www.googleapis.com/auth/calendar'
            }
        });
        if (error) {
            alert("Coś poszło nie tak");
            console.log(error);
        }
    }

    return ((isInitialized) ? (isLoggedIn) ? children : <div className={"login-required"}>
        <h1>Zaloguj się</h1>
        <p>Musisz się zalogować z konta infotechowego</p>
        <p>
            <button title="signIn" className='googleButton' onClick={() => googleSignIn()}><img alt={"Google icon"}
                                                                                                src={`${webUrl}/images/google.svg`}/>Zaloguj
                z Google
            </button>
        </p>
    </div> : (<div></div>))
}

export default LoginRequired