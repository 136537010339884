import React, {useEffect, useState} from 'react';
import {useSession} from "@supabase/auth-helpers-react";
import {apiUrl, getAxiosConfig, calcDuration, parseDate} from "../../../common";
import "./Admin.css"
import axios from "axios";
import QuestData from "../../Data.json"
import {useParams} from "react-router-dom";

const User = () => {
    const [questList, setQuestList] = useState([])
    const {id} = useParams()
    const session = useSession()

    useEffect(() => {
        if (!session) return
        axios.get(`${apiUrl}/admin/user/${id}`, getAxiosConfig(session))
            .then((response) => {
                if (!response.data.error)
                    setQuestList(response.data)
            })
    }, [session, id])

    const getQuestById = (id) => {
        return QuestData.find(value => value.id === id) || {"title": "Nieznany quest", "description": ""}
    }

    return (<div className={"admin-quest-list-container"}>
        {questList.map((value, index) => {
            return <a key={index} href={`/admin/quest/${value.id}`}>
                <div
                    className={(value.submission_status === "pending") ? "quest-item quest-pending" : "quest-item quest-green"}>
                    <h2>{getQuestById(value.quest_id).title}</h2>
                    {(parseDate(value.time_finished) - parseDate(value.time_started) > 0) ? (<h3>Zrobiony w {calcDuration(value.time_started, value.time_finished)}</h3>) : (<h3>Bez limitu czasu</h3>)}
                    <p>{(value.submission_status === "pending") ? "Do sprawdzenia" : (<>
                        <b>{value.points}</b> punktów</>)}</p>
                </div>
            </a>
        })}
    </div>)
}

export default User