import React, {useEffect, useState} from 'react';
import axios from 'axios';
import './Quests.css'
import {getAxiosConfig, apiUrl} from "../../common.js"
import {useSession} from "@supabase/auth-helpers-react";
import {useParams} from "react-router-dom";

const Quest = () => {
    const {id} = useParams()
    const [image, setImage] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false)
    const [alreadyTaken, setAlreadyTaken] = useState(false)
    const [submissionStatus, setSubmissionStatus] = useState("")
    const [points, setPoints] = useState(0)
    const session = useSession();

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();

        const imageFile = event.dataTransfer.files[0];

        if (imageFile.type.match("image/.*")) {
            setImage(imageFile);
        }
    };

    useEffect(() => {
        if (isInitialized || !session) return
        axios.get(`${apiUrl}/submit/${id}/status`, getAxiosConfig(session))
            .then((response) => {
                if (response.data.alreadyTaken) {
                    setAlreadyTaken(true)
                    setSubmissionStatus(response.data.submissionStatus)
                    setPoints(response.data.points)
                }
            })
        setIsInitialized(true)
    }, [session, isInitialized]);

    const openFileSelector = () => {
        document.querySelector("#fileSelector").click()
    }

    const handleFileOpen = (ev) => {
        const files = ev.target.files
        if(files.length > 0)
            setImage(files[0])
    }

    const sendAnswer = () => {
        const formData = new FormData()
        formData.append("image", image)

        axios.post(`${apiUrl}/submit/${id}/image`, formData, getAxiosConfig(session))
            .then((response) => {
                if(response.data.success === true) {
                    document.location.reload()
                }
            })
    }

    return (<div className={"quiz-container"}>
        {alreadyTaken ? (
            <div className={submissionStatus === "pending" ? "pending-infobox" : "good-infobox"}><h3>Wysłano
                odpowiedź</h3>
                <p>{submissionStatus === "pending" ? "Jeszcze nie sprawdzono" : `Przyznano ${points} punktów`}</p>
            </div>) : (<><input onInput={handleFileOpen} id={"fileSelector"} type={"file"} style={{display: "none"}}/><div
            className="dropzone"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={openFileSelector}
        >
            {image ? <img src={URL.createObjectURL(image)} alt="Wysłany obrazek"/> :
                <p>Przeciągnij obrazek tutaj, lub kliknij, aby wybrać</p>}
        </div></>)}
        {image && <button className='quiz-button' onClick={sendAnswer}>Wyślij</button>}
    </div>);
};

export default Quest;
