import React, {useEffect, useState} from 'react';
import {useSession} from "@supabase/auth-helpers-react";
import {apiUrl, getAxiosConfig} from "../../../common";
import "./Admin.css"
import axios from "axios";

const AllUsers = () => {
    const [userList, setUserList] = useState([])
    const session = useSession()

    const plural = (word, quantity) => {
        if (word == "zadanie") {
            if (quantity === 1)
                return "zadanie"
            else if (quantity === 2 || quantity === 3)
                return "zadania"
            else
                return "zadań"
        }
        return word
    }

    useEffect(() => {
        if (!session) return
        axios.get(`${apiUrl}/admin/users/all`, getAxiosConfig(session))
            .then((response) => {
                if (!response.data.error)
                    setUserList(response.data)
            })
    }, [session])

    return (<div className={"admin-list-container"}>
        {userList.map((value, index) => {
            return <a key={index} href={`/admin/user/${value.userId}`}>
                <div className={"admin-user-item"}>
                    <h3>{value.fullName}</h3>
                    <h4>{value.userId}</h4>
                    <p>
                        <b>{value.totalPoints}</b> punktów{(value.pendingSubmissions > 0) ? " (+ nie sprawdzone zadania)" : ""}
                    </p>
                </div>
            </a>
        })}
    </div>)
}

export default AllUsers