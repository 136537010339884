import React, {useState, useEffect} from 'react';
import './Quests.css'
import {getAxiosConfig, apiUrl, webUrl} from "../../common.js"
import axios from "axios";
import {useSession} from "@supabase/auth-helpers-react";

const Quiz = () => {
    const [currentImage, setCurrentImage] = useState("");
    const [isInitialized, setIsInitialized] = useState(false)
    const [sessionId, setSessionId] = useState("")
    const [userScore, setUserScore] = useState(0);
    const [outOf, setOutOf] = useState(0);
    const [isQuizCompleted, setIsQuizCompleted] = useState(false);
    const session = useSession();

    useEffect(() => {
        if(isInitialized || !session)
            return
        axios.get(`${apiUrl}/quiz/quest11/start`, getAxiosConfig(session))
            .then(response => {
                if (response.data.success === true) {
                    setSessionId(response.data.sessionId)
                    loadNext("", response.data.sessionId)
                } else if (response.data.alreadyTaken) {
                    setUserScore(response.data.results.points)
                    setOutOf(response.data.results.outOf)
                    setIsQuizCompleted(true)
                } else {
                    setIsQuizCompleted(true)
                }
            })
        setIsInitialized(true)
    }, [session, isInitialized]);

    const loadNext = (answer = "", _sessionId = null) => {
        const sID = _sessionId || sessionId
        axios.get(`${apiUrl}/quiz/${sID}/next?answer=${answer}`)
            .then(response => {
                if ("next" in response.data) {
                    setCurrentImage(response.data.next.image)
                } else if ("results" in response.data) {
                    setUserScore(response.data.results.points)
                    setOutOf(response.data.results.outOf)
                    setIsQuizCompleted(true)
                }
            })
    }

    return (
        <div className='fake-container'>
            {isQuizCompleted ? (
                <div className={"good-infobox"}>
                    <h2>Quiz Completed!</h2>
                    <p>Your Score: {userScore} / {outOf}</p>
                </div>
            ) : (
                <div>
                    <img className='fake-image'
                         src={`${webUrl}/images/${currentImage}`}
                         alt={"Obrazek"}
                    />
                    <div>
                        <button onClick={() => loadNext(true)}>Człowiek</button>
                        <button onClick={() => loadNext(false)}>AI</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Quiz;
