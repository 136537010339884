import React from 'react';
import Bingo from './components/Bingo';
import './App.css';
import {useSession, useSupabaseClient} from '@supabase/auth-helpers-react';
import {adminList} from "./common";
import LoginRequired from "./components/LoginRequired"

const App = () => {
    async function signOut() {
        await supabase.auth.signOut();
        document.location.reload()
    }

    const session = useSession();
    const supabase = useSupabaseClient();

    return (<LoginRequired>
        <div className="App">
            <div>
                <h1 className="Title">Bingo</h1>
                <Bingo/>
                {session ? <>
                    <p>{session.user.user_metadata.full_name}</p>
                    <button title="signOut" className='logoutButton' onClick={() => signOut()}>Wyloguj</button>
                    {adminList.includes(session.user.email) && <a href={"/admin"}>
                        <button title={"admin"} className={"adminButton"}>Admin</button>
                    </a>}
                </> : <></>}
            </div>
        </div>
    </LoginRequired>);
};

export default App;
