import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from 'react-router-dom'; // Import Routes and Route
import {supabaseKey} from "./common"
import './index.css';
import App from './App';
import Admin from './components/admin/Admin';
import Quest from './components/Quest';  // Import the Quest component
import {createClient} from '@supabase/supabase-js';
import {SessionContextProvider} from '@supabase/auth-helpers-react';

const supabase = createClient(
    `https://spokevblbmeuyoqijjxi.supabase.co`,
    supabaseKey
);

//implement supabase google login and use the session to get the user email
//Start quest time on Quest reveal button click 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <React.StrictMode>
            <SessionContextProvider supabaseClient={supabase}>
                <Routes>
                    <Route exact path="/" element={<App/>}/>
                    <Route path="/quest/:id/" element={<Quest/>}/>
                    <Route path="/admin" element={<Admin/>}/>
                    <Route path="/admin/:section" element={<Admin/>}/>
                    <Route path="/admin/:section/:id" element={<Admin/>}/>
                </Routes>
            </SessionContextProvider>
        </React.StrictMode>
    </BrowserRouter>
);
