import React, {useEffect, useState} from 'react';
import {useSession} from "@supabase/auth-helpers-react";
import {apiUrl, getAxiosConfig} from "../../../common";
import "./Admin.css"
import axios from "axios";

const PendingList = () => {
    const [userList, setUserList] = useState([])
    const session = useSession()

    useEffect(() => {
        if (!session) return
        axios.get(`${apiUrl}/admin/users/pending`, getAxiosConfig(session))
            .then((response) => {
                if (!response.data.error)
                    setUserList(response.data)
            })
    }, [session])

    return (<div className={"admin-list-container"}>
        {userList.map((value, index) => {
            return <a key={index} href={`/admin/user/${value.userId}`}>
                <div className={"admin-user-item"}>
                    <h3>{value.fullName}</h3>
                    <h4>{value.userId}</h4>
                    <p>Zostało do
                        sprawdzenia <b>{value.pendingSubmissions}</b> z <b>{value.totalSubmissions}</b> odpowiedzi</p>
                </div>
            </a>
        })}
    </div>)
}

export default PendingList