import React from "react"
import {apiUrl} from "../../common";

const SubmissionPreview = ({submission}) => {
    if(!submission)
        return (<textarea placeholder={"Brak odpowiedzi..."} disabled
                   className={"quizTextarea"}
                   value={submission || ""}></textarea>)
    if (submission.startsWith("!IMAGE!")) {
        const imageUrl = submission.substring(7)
        return (<img alt={"Wysłany obrazek"} src={`${apiUrl}/${imageUrl}`}/>)
    }
    return (<textarea placeholder={"Brak odpowiedzi..."} disabled
                      className={"quizTextarea"}
                      value={submission || ""}></textarea>)
}

export default SubmissionPreview