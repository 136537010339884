import React from 'react';
import './Quest.css';
import data from './Data.json';
import QuestComponents from './QuestDirectory.js';
import {useParams} from 'react-router-dom'; // Import Routes and Route
import LoginRequired from "./LoginRequired";

const Quest = () => {
    const {id} = useParams(); // Destructure id from useParams
    const questDetail = data[id - 1]
    const QuestComponent = QuestComponents[id];


    return (
        <LoginRequired>
            <div>
                <h1 className='questTitle'><a href={"/"}>←&nbsp;&nbsp;</a>{questDetail.title}</h1>
                <h3 className='questDescription'>{questDetail.description}</h3>
                {QuestComponent && <QuestComponent/>}
            </div>
        </LoginRequired>
    );
};

export default Quest;