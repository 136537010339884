import React, {useEffect, useState} from 'react';
import data from './Data.json'
import './Bingo.css'
import {useSession} from "@supabase/auth-helpers-react";
import {getAxiosConfig} from "../common";
import {apiUrl} from "../common";
import axios from "axios";

const Bingo = () => {
    const [submitted, setSubmitted] = useState({})
    const [isInitialized, setIsInitialized] = useState(false)
    const session = useSession()

    useEffect(() => {
        if(isInitialized || !session)
            return
        axios.get(`${apiUrl}/alreadyTaken/list`, getAxiosConfig(session))
            .then((response) => {
                if (!response.data.error) {
                    setSubmitted(response.data)
                }
            })
        setIsInitialized(true)
    }, [isInitialized, session])

    return (<div className="bingo-container">
            {data.map(item => (  // Use 'item' as the parameter here
                <a key={item.id} href={`quest/${item.id}`} rel="noopener noreferrer">
                    <div id={item.class}
                         className={"bingo-item " + (submitted[item.id] !== undefined ? ((submitted[item.id].submissionStatus === "pending") ? "bingo-submission-pending" : "bingo-submission-taken") : "bingo-submission-clear")}>
                        <p>{item.title}</p>
                    </div>
                </a>))}
        </div>);
};

export default Bingo;
