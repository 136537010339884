import React, {useEffect, useState} from 'react';
import axios from 'axios';
import './Quests.css'
import {getAxiosConfig, apiUrl} from "../../common.js"
import {useSession} from "@supabase/auth-helpers-react";

const Quest1 = () => {
    const [userInput, setUserInput] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [isInitialized, setIsInitialized] = useState(false)
    const [alreadyTaken, setAlreadyTaken] = useState(false)
    const [submissionStatus, setSubmissionStatus] = useState("")
    const [points, setPoints] = useState(0)
    const [timeStarted, setTimeStarted] = useState("")
    const session = useSession();

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    useEffect(() => {
        if (isInitialized || !session) return
        const currentDate = new Date()
        setTimeStarted(currentDate.toISOString())
        axios.get(`${apiUrl}/submit/1/status`, getAxiosConfig(session))
            .then((response) => {
                if (response.data.alreadyTaken) {
                    setAlreadyTaken(true)
                    setSubmissionStatus(response.data.submissionStatus)
                    setPoints(response.data.points)
                }
            })
        setIsInitialized(true)
    }, [session, isInitialized]);

    const makeApiCall = () => {
        if (!isInitialized || !session)
            return
        axios.post(`${apiUrl}/submit/1/text`, {timeStarted, message: userInput}, getAxiosConfig(session))
            .then((response) => {
                if (response.data.isEmpty === true) {
                    setResponseMessage("Nie możesz wysłać pustej odpowiedzi")
                } else if (response.data.error) {
                    setResponseMessage("Coś poszło nie tak")
                } else if (response.data.alreadyTaken === true) {
                    setResponseMessage("Już oddałeś to zadanie")
                } else if (response.data.success === true) {
                    setAlreadyTaken(true);
                    setSubmissionStatus("pending")
                }
            })
    };

    return (<div className={"quiz-container"}>
        {alreadyTaken ? (
            <div className={submissionStatus === "pending" ? "pending-infobox" : "good-infobox"}><h3>Wysłano
                odpowiedź</h3>
                <p>{submissionStatus === "pending" ? "Jeszcze nie sprawdzono" : `Przyznano ${points} punktów`}</p>
            </div>) : (<div className={"submit-container"}><p><textarea placeholder={"Enter something..."}
                                                                        onChange={handleInputChange}
                                                                        className={"quizTextarea"}
                                                                        value={userInput}></textarea></p>
            <p>
                <button className="quizSubmit" onClick={makeApiCall}>Submit</button>
            </p>
            <p>{responseMessage}</p>
        </div>)}</div>);
};

export default Quest1;
