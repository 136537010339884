import Quest1 from './quests/Quest1';
import Quest2 from './quests/Quest2';
import Quest3 from './quests/Quest3';
import Quest4 from './quests/Quest4';
import Quest5 from './quests/Quest5';
import Quest6 from './quests/Quest6';
import Quest7 from './quests/Quest7';
import Quest8 from './quests/Quest8';
import Quest9 from './quests/Quest9';
import Quest10 from './quests/Quest10';
import Quest11 from './quests/Quest11';
import Quest12 from './quests/Quest12';
import Quest13 from './quests/Quest13';
import Quest14 from './quests/Quest14';
import Quest15 from './quests/Quest15';
import Quest16 from './quests/Quest16';

const questComponents = {
  1: Quest1,
  2: Quest2,
  3: Quest3,
  4: Quest4,
  5: Quest5,
  6: Quest6,
  7: Quest7,
  8: Quest8,
  9: Quest9,
  10: Quest10,
  11: Quest11,
  12: Quest12,
  13: Quest13,
  14: Quest14,
  15: Quest15,
  16: Quest16,
};

export default questComponents;
