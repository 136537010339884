import React, {useEffect, useState} from 'react';
import {useSession} from "@supabase/auth-helpers-react";
import {apiUrl, getAxiosConfig} from "../../../common";
import "./Admin.css"
import axios from "axios";
import {useParams} from "react-router-dom";
import QuestData from "../../Data.json";
import SubmissionPreview from "../SubmissionPreview";

const Quest = () => {
    const [questData, setQuestData] = useState([])
    const [points, setPoints] = useState(0)
    const [submissionStatus, setSubmissionStatus] = useState("")
    const {id} = useParams()
    const session = useSession()

    useEffect(() => {
        if (!session) return
        axios.get(`${apiUrl}/admin/quest/${id}`, getAxiosConfig(session))
            .then((response) => {
                if (!response.data.error) {
                    setQuestData(response.data)
                    setPoints(response.data.points || 0)
                    setSubmissionStatus(response.data.submission_status)
                }
            })
    }, [session, id])

    const getQuestById = (id) => {
        return QuestData.find(value => value.id === id) || {"title": "Nieznany quest", "description": ""}
    }

    const handlePointsChange = (ev) => {
        let newPoints = 0
        if (ev.target.value === "") {
            newPoints = 0
        } else if (ev.target.value.startsWith("0") && ev.target.value.length !== 1) {
            newPoints = ev.target.value.substring(1)
        } else {
            newPoints = ev.target.value
        }

        setPoints(newPoints)
        axios.get(`${apiUrl}/admin/quest/${id}/set/points/${newPoints}`, getAxiosConfig(session)).then()
    }

    const handleStatusChange = (ev) => {
        const newStatus = (ev.target.checked) ? "checked" : "pending"
        setSubmissionStatus(newStatus)
        axios.get(`${apiUrl}/admin/quest/${id}/set/status/${newStatus}`, getAxiosConfig(session)).then()
    }

    return (questData ? (<div className={"admin-quest-container"}>
        <div className={"admin-quest"}>
            <h1>{getQuestById(questData.quest_id).title}</h1>
            <h3 className={"questDescription"}>{getQuestById(questData.quest_id).description}</h3>
            <h4>Oddał(a): {questData.fullName}</h4>
            <p><SubmissionPreview submission={questData.submission}/></p>
            <p><input type={"number"} onWheel={(ev) => ev.target.blur()} onChange={handlePointsChange} value={points}/>
            </p>
            <p><input type={"checkbox"} onChange={handleStatusChange} checked={submissionStatus !== "pending"}
                      id={"statusCheck"} name={"statusCheck"}/> <label htmlFor={"statusCheck"}>Sprawdzone</label></p>
        </div>
    </div>) : (<></>))
}

export default Quest