import React, {useState, useEffect} from 'react';
import AdminComponents from "./AdminDirectory"
import "../Admin.css";
import {adminList} from "../../common"
import AdminDashboard from "./AdminDashboard"
import {useSession} from "@supabase/auth-helpers-react";
import {useParams} from "react-router-dom";
import LoginRequired from "../LoginRequired";

const Admin = () => {

    const {section} = useParams();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const session = useSession()
    const AdminComponent = AdminComponents[section];

    useEffect(() => {
        if (!session) {
            setIsAdmin(false)
        } else {
            setIsAdmin(adminList.includes(session.user.email))
            setIsInitialized(true)
        }
    }, [session]);

    return (<LoginRequired>
        <div className={"App"}>
            <h1 className={"Title"}><a href={AdminComponent ? "/admin" : "/"}>←&nbsp;&nbsp;</a>Panel administracyjny</h1>
            <div className="admin-container">
                {isAdmin ? ((AdminComponent ? <AdminComponent/> : <AdminDashboard/>)) : (isInitialized && (
                    <div className="bad-infobox">
                        <h3>Forbidden</h3>
                        <p>
                            You aren't allowed to see this page
                        </p>
                    </div>))}
            </div>
        </div>
    </LoginRequired>);
};

export default Admin;