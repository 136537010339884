import React, {useState, useEffect} from 'react';
import './Quests.css'
import {getAxiosConfig, apiUrl} from "../../common.js"
import axios from "axios";
import {useSession} from "@supabase/auth-helpers-react";

const Quiz = () => {
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [answers, setAnswers] = useState([]);
    const [isInitialized, setIsInitialized] = useState(false)
    const [sessionId, setSessionId] = useState("")
    const [userScore, setUserScore] = useState(0);
    const [outOf, setOutOf] = useState(0);
    const [isQuizCompleted, setIsQuizCompleted] = useState(false);
    const session = useSession();

    useEffect(() => {
        if (isInitialized || !session) return
        axios.get(`${apiUrl}/quiz/quest6/start`, getAxiosConfig(session))
            .then(response => {
                if (response.data.success === true) {
                    setSessionId(response.data.sessionId)
                    loadNext(-1, response.data.sessionId)
                } else if (response.data.alreadyTaken) {
                    setUserScore(response.data.results.points)
                    setOutOf(response.data.results.outOf)
                    setIsQuizCompleted(true)
                } else {
                    setIsQuizCompleted(true)
                }
            })
        setIsInitialized(true)
    }, [session, isInitialized]);

    const loadNext = (answer = -1, _sessionId = null) => {
        const sID = _sessionId || sessionId
        axios.get(`${apiUrl}/quiz/${sID}/next?answer=${answer}`)
            .then(response => {
                if ("next" in response.data) {
                    setCurrentQuestion(response.data.next.question)
                    setAnswers(response.data.next.answers)
                } else if ("results" in response.data) {
                    setUserScore(response.data.results.points)
                    setOutOf(response.data.results.outOf)
                    setIsQuizCompleted(true)
                }
            })
    }

    const quizButtonContainer = (answers) => {
        const rows = [];
        for (let i = 0; i < answers.length; i += 2) {
            rows.push(<div className={"quiz-button-row"} key={i}>
                <button className='quiz-button' onClick={() => loadNext(i)}>{answers[i]}</button>
                {answers[i + 1] && (
                    <button className='quiz-button' onClick={() => loadNext(i + 1)}>{answers[i + 1]}</button>)}
            </div>);
        }
        return rows;
    };

    return (<div className='quiz-container'>
            {isQuizCompleted ? (<div className={"good-infobox"}>
                    <h2>Quiz Completed!</h2>
                    <p>Your Score: {userScore} / {outOf}</p>
                </div>) : (<div className='submit-container'>
                    <h3>{currentQuestion}</h3>
                    <div className="answer-container">
                        {quizButtonContainer(answers)}
                    </div>
                </div>)}
        </div>);
}

export default Quiz;
